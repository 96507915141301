<template>
  <div class="text-center my-5">
    <p
      class="hscript text-h3 leading-scriptmobile md:text-script md:leading-script"
      v-html="quote"
    ></p>
    <p
      class="text-h6mobile md:text-h6 uppercase hsans mt-7 md:mt-14 mb-14"
      v-if="citation"
    >
      - {{ citation }}
    </p>
  </div>
</template>
<script>
export default {
  props: {
    quote: {
      required: true,
      type: String,
    },
    citation: {
      required: false,
      type: String,
    },
  },
};
</script>
