<template>
  <div class="text-black text-center">
    <p
      v-if="index == '1985'"
      class="text-center text-orange hserif text-h2mobile md:text-h3"
    >
      The Beginning
    </p>
    <a
      v-bind:id="'y' + index"
      class="block text-h1mobile md:text-h1 hserif mb-10"
      ref="year"
    >
      <span v-if="index != '1985'">{{ index }}</span>
    </a>
  </div>
</template>
<script>
export default {
  props: {
    index: {
      type: String,
    },
  },
  methods: {
    isVisible() {
      var rect = this.$refs.year.getBoundingClientRect();
      var test = rect.top - window.innerHeight / 2;
      if (test >= -50 && test <= 50) {
        this.$store.commit("setSelectedYear", this.index);

        //console.log("setSelectedYar!", this.index, test);
      }
    },
  },
  mounted() {
  if(this.index != '1985')  window.addEventListener("scroll", this.isVisible);
  },
};
</script>
