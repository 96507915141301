<template>
  <div class="fixed lg:static bottom-1 w-full hsans z-30 text-black left-0">
    <div class="container px-4 mx-auto">
      <ul class="flex justify-center mb-10">
        <li class="mx-8">
          <a
            @click="open()"
            class="hsans flex items-center bg-white px-4 py-2 shadow-lg lg:shadow-none rounded cursor-pointer"
            ><div class="h-px bg-black w-7 lg:w-14 mr-4 lg:mr-8"></div>
            <span
              style="transition: all 0.5s ease-out"
              class="mr-2 hover:mr-5"
              v-if="!filter"
              >Refine<span class="hidden lg:inline"> Timeline</span></span
            >
            <span
              style="transition: all 0.5s ease-out"
              class="mr-2 hover:mr-5 text-center"
              v-else
              v-html="filter.name"
            ></span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              v-if="!filter"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              v-if="filter"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
            <div class="h-px bg-black w-7 lg:w-14 ml-4 lg:ml-8"></div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    filter() {
      if (this.$store.state.artistsFilter.length)
        return this.$store.state.artistsFilter[0];
      if (this.$store.state.locationsFilter.length)
        return this.$store.state.locationsFilter[0];
      return null;
    },
    isFiltered() {
      if (
        this.$store.state.artistsFilter.length ||
        this.$store.state.locationsFilter.length
      ) {
        return true;
      }

      return false;
    },
  },
  methods: {
    open() {
      if (this.isFiltered) {
        this.$store.commit("viewAll");
      } else {
        this.$store.commit("setFilterModal", true);
        document.body.style.overflow = "hidden";
      }
    },
  },
};
</script>
