<template>
  <!-- timeline -->
  <div>
    <div class="md:container md:px-6 md:mx-auto">
      <div class="grid grid-cols-12">
        <div class="col-span-12 lg:col-span-10 lg:col-start-2">
          <template v-for="(data, yindex) in timestream" :key="yindex">
            <YearBlock
              :index="yindex"
              v-ani="{ class: 'fade-in-bottom', delay: 0 }"
            />

            <template v-for="(entry, index) in data.entries" :key="index">
              <component
                :is="alignment(yindex)"
                :entry="entry"
                @click="setCursor(yindex, index)"
                :id="yindex + index"
                v-ani="{ class: 'fade-in-bottom', delay: 0 }"
              />

              <Quote
                :quote="entry.quote"
                :citation="entry.citation"
                v-if="entry.quote"
                v-ani="{ class: 'fade-in-bottom', delay: 0 }"
              />
            </template>

            <Discover
              v-if="0"
              @discover="discover"
              :length="data.entries.length"
              :showcount="data.showCount"
              :yindex="yindex"
              :showing="showCountFunc(yindex, data.showCount)"
              v-ani="{ class: 'fade-in-bottom', delay: 0 }"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
  <!-- timeline -->
</template>
<script>
/* eslint-disable vue/no-unused-components */
import YearScroller from "../components/YearScroller.vue";
import YearBlock from "../components/YearBlock.vue";
import EntryLeft from "../components/EntryLeft.vue";
import EntryRight from "../components/EntryRight.vue";
import Quote from "../components/Quote.vue";
import Discover from "../components/Discover.vue";
export default {
  components: {
    YearScroller,
    YearBlock,
    EntryLeft,
    EntryRight,
    Quote,
    Discover,
  },
  data() {
    return {
      showCount: {},
    };
  },
  methods: {
    discover(e, y) {
      console.log("discover!", e, y);
      this.showCount[y] = e;
    },
    setCursor(yindex, index) {
      console.log(yindex, index);
      this.$store.commit("setYindex", yindex);
      this.$store.commit("setIndex", index);
      this.$store.commit("setFullscreen", false);
      this.$store.commit("setSelectedIndex", 0);
      this.$store.commit("setModal", true);
      document.body.style.overflow = "hidden";
    },
    alignment(i) {
      if (i % 2 === 0) return "EntryRight";
      return "EntryLeft";
    },
    showCountFunc(t, d) {
      if (this.showCount[t]) return this.showCount[t];
      return d;
    },
  },
  computed: {
    timestream() {
      return this.$store.getters.timestream;
    },
  },
};
</script>
