<template>
  <div
    class="hidden fixed left-0 top-0 lg:flex items-center h-screen w-20 z-30"
  >
    <div>
      <a @click="upYear()" style="cursor: pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 mx-auto text-orange mb-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M5 15l7-7 7 7"
          />
        </svg>
      </a>
      <ul
        class="pl-6 w-20 h-64 noscroll hsans"
        id="linkscroll"
        style="overflow: scroll"
      >
        <li
          v-for="(data, yindex) in timestream"
          :key="yindex"
          class="mb-7 text-sm"
        >
          <a
            v-if="yindex != '1985'"
            :href="'#y' + yindex"
            @click="setYear(yindex)"
            :id="'link_' + yindex"
            class="un cursor-pointer text-h6"
            v-bind:class="{
              'text-black': selectedYear == yindex,
              'text-orange': selectedYear != yindex,
            }"
            >{{ yindex }}</a
          >
        </li>
      </ul>

      <a @click="downYear()" style="cursor: pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 mx-auto text-orange mt-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    upYear() {
      var keys = Object.keys(this.timestream);
      var index = keys.indexOf(this.selectedYear.toString());
      if (this.timestream[keys[index - 1]]) {
        window.location.href = "#y" + keys[index - 1];
        this.$store.commit("setSelectedYear", keys[index - 1]);
      }
    },
    downYear() {
      var keys = Object.keys(this.timestream);
      var index = keys.indexOf(this.selectedYear.toString());
      if (this.timestream[keys[index + 1]]) {
        window.location.href = "#y" + keys[index + 1];
        this.$store.commit("setSelectedYear", keys[index + 1]);
      }
    },
    setYear(yindex) {
      console.log("setyear!");
      this.$store.commit("setSelectedYear", yindex);
    },
    scrollToYear() {
      window
        .$("#link_" + this.selectedYear)
        .get(0)
        .scrollIntoView({
          block: "center",
          inline: "center",
        });
    },
  },
  watch: {
    selectedYear() {
      console.log("change selected yer!", "#link_" + this.selectedYear);
      this.scrollToYear();
    },
  },
  computed: {
    selectedYear() {
      return this.$store.state.selectedYear;
    },
    timestream() {
      return this.$store.getters.timestream;
    },
  },
  mounted() {
    this.setYear(1992);
  },
};
</script>
