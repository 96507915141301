<template>
  <transition name="hf">
    <ul
      ref="hm"
      class="lg:hidden fixed w-screen flex bg-white shadow-xl overflow-x-scroll ns z-40 hsans left-0"
      v-show="show"
    >
      <li v-for="(data, yindex) in timestream" :key="yindex">
        <a
          :id="'mlink_' + yindex"
          :href="'#y' + yindex"
          @click="setMe(yindex)"
          v-bind:class="{
            'border-white': selectedYear != yindex,
            'border-orange': selectedYear == yindex,
          }"
          class="inline-block text-black pt-4 pb-5 border-b-2 hover:border-orange text-h6mobile px-5 cursor-pointer"
          >{{ yindex }}</a
        >
      </li>
    </ul>
  </transition>
</template>
<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  watch: {
    selectedYear() {
      console.log("mobile change year!", "#link_" + this.selectedYear);
      this.scrollToYear();
    },
  },
  methods: {
    scrollToYear() {
      window
        .$("#mlink_" + this.selectedYear)
        .get(0)
        .scrollIntoView({
          block: "center",
          inline: "center",
        });
    },
    setMe(yindex) {
      this.$store.commit("setSelectedYear", yindex);
    },
  },
  computed: {
    selectedYear() {
      return this.$store.state.selectedYear;
    },
    timestream() {
      return this.$store.getters.timestream;
    },
  },
  mounted() {
    window.addEventListener("scroll", () => {
      this.show = false;
      if (window.scrollY > 200) this.show = true;
    });
  },
};
</script>
<style>
.ns {
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

.ns::-webkit-scrollbar {
  display: none;
}
</style>
