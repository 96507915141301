<template>
  <button class="relative group abtn">
    <div
      class="group-hover:text-white"
      style="position: relative; z-index: 10; transition: all 0.5s"
    >
      <slot></slot>
    </div>
    <div
      class="rounded-full group-hover:bg-black blah"
      style="
        z-index: 5;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: all 0.25s;
      "
    ></div>
  </button>
</template>
<style>
.blah {
  transform: scale(1);
}
.abtn:hover .blah {
  transform: scale(1.5) !important;
}
</style>
