<template>
  <!-- header -->
  <div class="lg:h-32">
    <div class="lg:fixed lg:top-0 z-40 h-32">
      <div v-ani="{ class: 'fade-in-top', delay: 0 }">
        <div class="grid grid-cols-12 gap-5 h-32">
          <a href="#top" class="col-span-12 flex h-32">
            <img class="w-64" src="/hw30img/hw30-logo-v3.svg" />
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- end header -->
</template>
