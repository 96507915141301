<template>
  <div class="md:container md:mx-auto grid grid-cols-12 px-6 text-black pb-20">
    <div
      class="col-span-12 lg:col-span-10 lg:col-start-2 xl:col-span-8 xl:col-start-3 text-center"
    >
      <h1
        class="mb-5 md:mb-8 hsans text-h6mobile md:text-h6 uppercase"
        v-html="master.eyebrow_text"
      ></h1>
      <p
        class="hserif text-h2mobile leading-8 md:text-h2 md:leading-h2"
        v-html="master.intro_main_text"
      ></p>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    master() {
      return this.$store.state.master;
    },
  },
};
</script>
<style>
@keyframes bg-ani-up {
  0% {
  }
  100% {
  }
}
</style>
