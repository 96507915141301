<template>
  <div
    class="container-fluid mx-6"
    style="
      background: url('/hw30img/30th-Names-NimrodMT-Outline-03.svg');
      background-position: top left;
    "
  >
    <div id="top" class="hgrad">
      <Modal></Modal>
      <FilterModal></FilterModal>
      <YearScrollerMobile></YearScrollerMobile>
      <YearScroller
        v-ani="{ class: 'scale-up-center', delay: 0 }"
      ></YearScroller>
      <Header></Header>
      <TimelineText
        v-ani="{ class: 'fade-in-bottom', delay: 0 }"
      ></TimelineText>
      <StickyNav v-ani="{ class: 'fade-in-bottom', delay: 0 }"></StickyNav>
      <TimelinePane
        v-ani="{ class: 'fade-in-bottom', delay: 0 }"
      ></TimelinePane>

      <div class="mt-10 mb-32 lg:mb-10 text-center">
        <a href="https://www.hauserwirth.com" class="un text-black"
          >www.hauserwirth.com</a
        >
        <p>Copyright © 2022 Hauser & Wirth</p>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Modal from "../components/Modal.vue";
import YearScroller from "../components/YearScroller.vue";
import YearScrollerMobile from "../components/YearScrollerMobile.vue";
import FilterModal from "../components/FilterModal.vue";
import TimelineText from "../components/TimelineText.vue";
import StickyNav from "../components/StickyNav.vue";
import TimelinePane from "../components/TimelinePane.vue";
export default {
  components: {
    Header,
    YearScroller,
    YearScrollerMobile,
    FilterModal,
    TimelineText,
    StickyNav,
    TimelinePane,
    Modal,
  },
};
</script>
<style scoped>
.hgrad {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 25%,
    rgba(255, 255, 255, 1) 75%,
    rgba(255, 255, 255, 0) 100%
  );
}
</style>
