<template>
  <div v-for="(obj, index) in media" :key="index">
    <div v-show="index == selectedIndex">
      <template v-if="obj.acf_fc_layout == 'image'">
        <div
          @click="toggleFullscreen()"
          v-touch:swipe.left="next"
          v-touch:swipe.right="prev"
          v-bind:class="{ 'h-72': !fullscreen, gfull: fullscreen }"
          class="mx-auto himgg cursor-pointer w-full bg-contain bg-center bg-no-repeat"
          v-bind:style="{ backgroundImage: 'url(' + obj.image_file.url + ')' }"
        />
        <p
          class="text-h8 mt-8 hsans text-center h-special overflow-hidden"
          v-if="obj.image_title"
          v-html="obj.image_title"
        ></p>
        <p
          class="text-h8 mt-8 hsans text-center h-special overflow-hidden"
          v-if="obj.image_footnote"
          v-html="obj.image_footnote"
        ></p>
      </template>
      <template v-if="obj.acf_fc_layout == 'video'">
        <iframe :src="obj.embed_url" frameborder="0" class="w-full"></iframe>
        <p
          class="text-h8 mt-8 hsans text-center h-12 overflow-hidden"
          v-if="obj.video_title"
          v-html="obj.video_title"
        ></p>
        <p
          class="text-h8 mt-8 hsans text-center h-12 overflow-hidden"
          v-if="obj.video_footnote"
          v-html="obj.video_footnote"
        ></p>
      </template>
    </div>
  </div>

  <div class="flex mt-9" v-if="media.length">
    <AniButton @click="prev()" class="mr-auto">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M10 19l-7-7m0 0l7-7m-7 7h18"
        /></svg
    ></AniButton>
    <div class="flex items-center">
      <div
        v-for="(obj, index) in media"
        :key="index"
        class="flex-1 w-5 mx-1 h-1"
        v-bind:class="{
          'bg-black': index == selectedIndex,
          'bg-gray': index != selectedIndex,
        }"
      ></div>
    </div>
    <AniButton @click="next()" class="ml-auto">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M14 5l7 7m0 0l-7 7m7-7H3"
        />
      </svg>
    </AniButton>
  </div>
</template>
<script>
import AniButton from "../components/AniButton.vue";
//import InnerImageZoom from 'vue-inner-image-zoom';
export default {
  components: {
    AniButton,
    //  'inner-image-zoom': InnerImageZoom
  },
  computed: {
    selectedIndex() {
      return this.$store.state.selectedIndex;
    },
    fullscreen() {
      return this.$store.state.fullscreen;
    },
  },
  methods: {
    toggleFullscreen() {
      this.$store.commit("setFullscreen", !this.fullscreen);
    },
    next() {
      var next = this.selectedIndex + 1;
      if (next >= this.media.length) next = 0;
      this.$store.commit("setSelectedIndex", next);
    },
    prev() {
      var prev = this.selectedIndex - 1;
      if (prev < 0) prev = this.media.length - 1;
      this.$store.commit("setSelectedIndex", prev);
    },
  },
  props: {
    media: {
      required: false,
      type: Object,
    },
  },
};
</script>
<style>
.gfull {
  height: 50vh;
}
.h-special {
  height: 3.5rem;
}
.himgg {
  cursor: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_b)'%3E%3Crect width='40' height='40' rx='20' fill='%23222222'/%3E%3C/g%3E%3Cpath d='M12 20L28.9706 20' stroke='white' stroke-linejoin='round'/%3E%3Cpath d='M20.4854 11.5148L20.4854 28.4853' stroke='white' stroke-linejoin='round'/%3E%3Cdefs%3E%3Cfilter id='filter0_b' x='-4' y='-4' width='48' height='48' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeGaussianBlur in='BackgroundImage' stdDeviation='2'/%3E%3CfeComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A")
      32 32,
    pointer !important;
}
</style>
