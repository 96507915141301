<template>
  <div
    id="filterModal"
    class="fixed z-50 w-screen left-0"
    v-show="isFilterModalActive"
    style="background-color: rgba(0, 0, 0, 0.025)"
  >
    <div class="grid grid-cols-12">
      <div
        @click="hideFilterModal()"
        class="hidden lg:block lg:col-span-8 xl:col-span-9"
      ></div>
      <transition name="hfm">
        <div
          v-show="isFilterModalActive"
          class="col-span-12 lg:col-span-4 xl:col-span-3 bg-white p-5"
        >
          <div class="absolute top-7 right-5 lg:hidden">
            <AniButton @click="hideFilterModal()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </AniButton>
          </div>
          <div class="hidden" style="height: 50vh; overflow: hidden">
            <p class="hserif text-h2mobile mb-5">View by Artist</p>
            <ul
              class="absolute w-full hscroll"
              style="
                bottom: 2rem;
                top: 3rem;
                overflow-y: scroll;
                overflow-x: hidden;
                border-bottom: 1px solid #eaeaea;
              "
            >
              <li
                v-for="(artist, index) in sortedArtists"
                :key="index"
                class="mb-1"
              >
                <a
                  @click="setArtistFilter(artist)"
                  :class="{
                    'font-bold': artistsFilter.includes(artist.slug),
                  }"
                  class="cursor-pointer un text-black py-2 hsans"
                  v-html="artist.name"
                ></a>
              </li>
            </ul>
          </div>
          <div class="relative" style="height: 100vh; overflow: hidden">
            <p class="hserif text-h2mobile mb-5">View by Location</p>
            <ul
              class="absolute w-full hscroll"
              style="
                bottom: 3.5rem;
                top: 3rem;
                overflow-y: scroll;
                overflow-x: hidden;
              "
            >
              <li
                v-for="(location, index) in sortedLocations"
                :key="index"
                class="mb-1"
              >
                <a
                  @click="setLocationFilter(location)"
                  :class="{
                    'font-bold': locationsFilter.includes(location.slug),
                  }"
                  class="cursor-pointer un text-black py-2 hsans"
                  v-html="location.name"
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import AniButton from "../components/AniButton.vue";
export default {
  components: {
    AniButton,
  },
  methods: {
    setArtistFilter(artist) {
      this.hideFilterModal();
      this.$store.commit("setArtistsFilter", artist);
    },
    setLocationFilter(location) {
      this.hideFilterModal();
      this.$store.commit("setLocationsFilter", location);
    },
    hideFilterModal() {
      this.$store.commit("setFilterModal", false);
      document.body.style.overflow = "scroll";
    },
    toggleNav(navTo) {
      if (this.nav == navTo) {
        this.nav = "";
      } else {
        this.nav = navTo;
      }
    },
    viewAll() {},
  },
  computed: {
    sortedArtists() {
      return [...this.artists].sort((a, b) => {
        var aname = a.name.split(" ");
        var bname = b.name.split(" ");

        if (aname[1] < bname[bname.length - 1]) return -1;
        if (aname[1] > bname[bname.length - 1]) return 1;
        return 0;
      });
    },

    sortedLocations() {
      return [...this.locations].sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
    },
    isFilterModalActive() {
      return this.$store.state.isFilterModalActive;
    },
    artists() {
      return this.$store.state.artists;
    },
    artistsFilter() {
      return this.$store.state.artistsFilter;
    },
    locations() {
      return this.$store.state.locations;
    },
    locationsFilter() {
      return this.$store.state.locationsFilter;
    },
  },
};
</script>
