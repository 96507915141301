<template>
  <div
    id="contentModal"
    class="fixed z-50 w-screen left-0"
    style="background-color: rgba(0, 0, 0, 0.025)"
    v-show="isModalActive"
  >
    <transition name="hsl">
      <div v-show="isModalActive">
        <div v-if="activeEntry.date" class="grid grid-cols-12">
          <div @click="close()" class="hidden lg:block lg:col-span-2"></div>
          <div
            class="col-span-12 lg:col-span-10 h-screen bg-white text-black overflow-y-scroll noscroll"
          >
            <div class="grid grid-cols-12">
              <div class="col-span-12 px-4 md:px-20">
                <div class="flex items-center mb-10 pt-6 pb-8 md:pt-12">
                  <div class="flex items-center w-full">
                    <p class="text-h2mobile hserif mr-auto">
                      {{ activeEntry.date[2] }}
                    </p>
                    <div class="flex mx-auto text-h6">
                      <AniButton @click="previous()" :disabled="index == 1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6 text-gray-300"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M15 19l-7-7 7-7"
                          />
                        </svg>
                      </AniButton>

                      <span class="mx-6 hsans"
                        >{{ index }} of {{ yIndexLength }}</span
                      >

                      <AniButton
                        @click="forward()"
                        :disabled="index == yIndexLength"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M9 5l7 7-7 7"
                          />
                        </svg>
                      </AniButton>
                    </div>
                    <AniButton @click="close()" class="ml-auto">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </AniButton>
                  </div>
                </div>

                <div
                  class="lg:px-10"
                  v-bind:class="{ forward: modalClass == 'forward' }"
                >
                  <div
                    class="grid grid-cols-5 gap-10 mb-20"
                    v-if="activeEntry.title"
                  >
                    <div
                      v-bind:class="{
                        'col-span-5 md:col-span-2': !fullscreen,
                        'col-span-5': fullscreen,
                      }"
                    >
                      <Gallery :media="activeEntry.media_gallery" />
                    </div>
                    <div class="col-span-5 md:col-span-3" v-if="!fullscreen">
                      <h1
                        class="text-h3 leading-h3 hserif mb-6"
                        v-html="activeEntry.title"
                      ></h1>
                      <p
                        v-if="activeEntry.short_description"
                        class="text-h5 leading-h5 hserif mb-6 p-6 border-l-2 border-orange"
                        v-html="activeEntry.short_description"
                      ></p>

                      <div
                        v-if="activeEntry.long_description"
                        class="hlong text-h6 leading-h5 hsans-reg"
                        v-html="activeEntry.long_description"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-span-1"></div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import Gallery from "../components/Gallery.vue";
import AniButton from "../components/AniButton.vue";
export default {
  data() {
    return {
      modalClass: "",
    };
  },
  components: {
    Gallery,
    AniButton,
  },
  computed: {
    fullscreen() {
      return this.$store.state.fullscreen;
    },
    yIndexLength() {
      return this.timestream[this.$store.getters.yindex].entries.length;
    },
    index() {
      return this.$store.getters.index + 1;
    },
    timestream() {
      return this.$store.getters.timestream;
    },
    isModalActive() {
      return this.$store.state.isModalActive;
    },
    activeEntry() {
      if (
        this.$store.getters.yindex != null &&
        this.$store.getters.index != null &&
        this.timestream[this.$store.getters.yindex] &&
        this.timestream[this.$store.getters.yindex].entries[
          this.$store.getters.index
        ]
      ) {
        return this.timestream[this.$store.getters.yindex].entries[
          this.$store.getters.index
        ];
      } else {
        return {};
      }
    },
  },
  methods: {
    expand() {
      this.fullscreen = !this.fullscreen;
      console.log("expand!");
    },
    previous() {
      this.$store.commit("setIndex", this.$store.getters.index - 1);
      this.$store.commit("setFullscreen", false);
      this.$store.commit("setSelectedIndex", 0);
    },
    forward() {
      this.modalClass = "forward";
      this.$store.commit("setIndex", this.$store.getters.index + 1);
      this.$store.commit("setFullscreen", false);
      this.$store.commit("setSelectedIndex", 0);
    },
    close() {
      this.$store.commit("setModal", false);
      document.body.style.overflow = "scroll";
    },
  },
};
</script>

<style>
.forward {
}
.hlong p {
  margin-bottom: 1rem;
}
</style>
