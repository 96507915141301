<template>
  <a
    v-if="length - showcount != 0"
    @click="discover()"
    class="text-h7 md:text-h6 text-orange cursor-pointer flex items-center mb-20 flex justify-center hsans"
  >
  <template v-if="this.length > this.showing">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-7 w-7 mr-1"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="{2}"
        d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>

    <span style="transition: all 0.5s" class="hover:ml-2 mr-1">Discover {{length - showcount}} more</span>
  </template>
  <template v-else>
    <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="{2}" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>
    <span style="transition: all 0.5s" class="hover:ml-2 mr-1">Show {{length - showcount}} less</span>
  </template>
  </a>
</template>
<script>
export default{
  methods:{
    discover(){
      if(this.length > this.showing){
        this.$emit('discover', this.length, this.yindex);
        return;
      }
        this.$emit('discover', this.showcount, this.yindex);
    }
  },
  props:{
    length:{
      type: Number,
      required: true
    },
    showcount:{
      type: Number,
      required: true
    },
    showing:{
      type: Number,
      required: true
    },
    yindex:{
      type: String,
      required: true
    }
  }
}
</script>
