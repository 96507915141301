<template>
  <div class="grid grid-cols-2 gap-5 md:grid-cols-12 md:gap-10 mb-20">
    <div class="col-span-1 md:col-span-7 text-right">
      <template v-if="entry.media_gallery[0]">
        <div
          v-if="entry.media_gallery[0].image_file"
          @mouseover="hover = true"
          @mouseleave="hover = false"
          class="shadow-xl overflow-hidden cursor-pointer inline-block"
        >
          <img
            v-if="entry.media_gallery[0].image_file.sizes"
            :src="entry.media_gallery[0].image_file.sizes.large"
            class="aspect-auto block ml-auto max-w-full"
            style="max-height: 50vh"
            v-bind:class="{ 'kenburns-top': hover, 'kenburns-out': !hover }"
          />
          <pre v-else>  Err 88: Image Deleted  </pre>
        </div>
        <pre v-if="!entry.media_gallery[0]">  NO IMAGE </pre>
      </template>
    </div>
    <div class="col-span-1 md:col-span-4 text-left">
      <h3
        v-if="entry.eyebrow_text"
        class="text-h6mobile md:text-h6 uppercase mb-1 hsans transition-colors"
      >
        {{ entry.eyebrow_text }}
      </h3>
      <p
        class="hserif text-h5 leading-h5 md:text-h3 md:leading-h3 bgt bg-clip-text text-transparent hcircle cursor-pointer"
        v-html="entry.title"
      ></p>

      <p
        v-if="entry.opening_paragraph"
        class="text-h6 leading-h5 hsans-reg mb-4 mt-4"
        v-html="entry.opening_paragraph"
      ></p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      hover: false,
    };
  },
  props: {
    entry: {
      required: true,
      type: Object,
    },
  },
};
</script>
